<template>
  <v-app>
    <TheHeader></TheHeader>
    <div v-show="loading" class="absolute w-100 h-100 z-20 flex align-center bg-white">
      <div class="w-full flex flex-column align-center justify-center">
        <client-only>
          <CarLoader />
        </client-only>
        <span class="title-h5"> טוען עמוד... </span>
      </div>
    </div>
    <main v-show="!loading">
      <slot />
    </main>

    <ClientOnly>
      <LazyCart />
      <LazyPopupCarForm />
      <LazyPopupPhone />
      <LazyPopupCarFilter />
      <LazyPopupCarSvg />
      <LazyPopupUpsaleTrunk />
      <LazyPopupUpsaleProduct />
      <LazyPopupContact />
    </ClientOnly>
    <SocialsFloat />
    <div>
      <TheFooter></TheFooter>
    </div>
  </v-app>
</template>
<script setup>
  const route = useRoute();
  const nuxtApp = useNuxtApp();

  useHead({
    script: [
      {
        tagPosition: "bodyClose",
        src: "/js/MorphSVGPlugin.min.js",
        defer: true,
        async: true,
      },
      {
        tagPosition: "bodyClose",
        src: "/js/TweenMax.min.js",
        defer: true,
        async: true,

      },
    ],
  });
  const loading = ref(false);

  onMounted(() => {
    if (process.client) {
      // prevents window to scrollTop then returns to savedPosition
      window.history.scrollRestoration = "auto";

      // but restore manual scrollRestoration at the first navigation
      const unwatch = useRouter().beforeEach(() => {
        window.history.scrollRestoration = "manual";
        unwatch();
      });

      // and restore auto scrollRestoration when leaving the page
      window.addEventListener("unload", () => {
        window.history.scrollRestoration = "auto";
      });
    }
  });

  nuxtApp.hook("page:loading:start", () => {
    // loading.value = true;
    if (process.client) {
      if (window) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }
    }
  });
  nuxtApp.hook("page:start", () => {
    loading.value = true;
    if (process.client) {
      if (window) {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: "instant",
        });
      }
    }
  });
  nuxtApp.hook("page:finish", () => {
    loading.value = false;
    if (process.client) {
      if (window) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }
    }
  });
</script>
<style></style>
